export var ColombiaRegion;
(function (ColombiaRegion) {
  ColombiaRegion["centro"] = "centro";
  ColombiaRegion["norte"] = "norte";
  ColombiaRegion["andes"] = "andes";
  ColombiaRegion["sur"] = "sur";
  ColombiaRegion["all"] = "all";
})(ColombiaRegion || (ColombiaRegion = {}));
export var SKU_LIMITS_DDCS = [{
  region: ColombiaRegion.centro,
  id: 'AV04',
  name: 'CD Tibasosa'
}, {
  region: ColombiaRegion.centro,
  id: 'AV06',
  name: 'CD Tocancipá'
}, {
  region: ColombiaRegion.centro,
  id: 'AV26',
  name: 'CD OL Autosur Bogotá'
}, {
  region: ColombiaRegion.centro,
  id: 'AV42',
  name: 'CD OL de Tunja'
}, {
  region: ColombiaRegion.centro,
  id: 'AV47',
  name: 'CD Sibaté'
}, {
  region: ColombiaRegion.centro,
  id: 'AV50',
  name: 'CD OL Siberia'
}, {
  region: ColombiaRegion.centro,
  id: 'AV53',
  name: 'BOG Bosa'
}, {
  region: ColombiaRegion.centro,
  id: 'AV54',
  name: 'BOG Engativá'
}, {
  region: ColombiaRegion.centro,
  id: 'AV66',
  name: 'CD Chiquinquirá'
}, {
  region: ColombiaRegion.centro,
  id: 'AV67',
  name: 'CD Sabanalarga'
}, {
  region: ColombiaRegion.centro,
  id: 'AV68',
  name: 'CD Urban Center Bogotá Verde'
}, {
  region: ColombiaRegion.centro,
  id: 'AV71',
  name: 'CD Urban Center Chapinero'
}, {
  region: ColombiaRegion.norte,
  id: 'AV03',
  name: 'CD Barranquilla'
}, {
  region: ColombiaRegion.norte,
  id: 'AV10',
  name: 'CD Turbaco'
}, {
  region: ColombiaRegion.norte,
  id: 'AV11',
  name: 'CD Santa Marta'
}, {
  region: ColombiaRegion.norte,
  id: 'AV12',
  name: 'CD Valledupar'
}, {
  region: ColombiaRegion.norte,
  id: 'AV13',
  name: 'CD Corozal'
}, {
  region: ColombiaRegion.norte,
  id: 'AV20',
  name: 'CD OL Cúcuta Local'
}, {
  region: ColombiaRegion.norte,
  id: 'AV29',
  name: 'CD OL San Andrés'
}, {
  region: ColombiaRegion.norte,
  id: 'AV30',
  name: 'CD OL Riohacha'
}, {
  region: ColombiaRegion.norte,
  id: 'AV31',
  name: 'CD OL Bosconia'
}, {
  region: ColombiaRegion.norte,
  id: 'AV32',
  name: 'CD OL Magangue'
}, {
  region: ColombiaRegion.norte,
  id: 'AV36',
  name: 'CD OL Fonseca'
}, {
  region: ColombiaRegion.norte,
  id: 'AV38',
  name: 'CD OL Curumani'
}, {
  region: ColombiaRegion.norte,
  id: 'AV39',
  name: 'CD Unión Monteria'
}, {
  region: ColombiaRegion.norte,
  id: 'AV40',
  name: 'CD Unión Caucasia'
}, {
  region: ColombiaRegion.norte,
  id: 'AV41',
  name: 'CD Unión Apartado'
}, {
  region: ColombiaRegion.norte,
  id: 'AV48',
  name: 'KACartagena'
}, {
  region: ColombiaRegion.norte,
  id: 'AV49',
  name: 'Barranquilla 2'
}, {
  region: ColombiaRegion.norte,
  id: 'AV56',
  name: 'Uce - Barranquilla Las Nieves'
}, {
  region: ColombiaRegion.norte,
  id: 'AV74',
  name: 'CD La Arenosa'
}, {
  region: ColombiaRegion.norte,
  id: 'AV69',
  name: 'CD CAR Torices'
}, {
  region: ColombiaRegion.andes,
  id: 'AV02',
  name: 'CD Bucaramanga'
}, {
  region: ColombiaRegion.andes,
  id: 'AV05',
  name: 'CD Itagüí'
}, {
  region: ColombiaRegion.andes,
  id: 'AV16',
  name: 'CD Pereira'
}, {
  region: ColombiaRegion.andes,
  id: 'AV22',
  name: 'CD Armenia'
}, {
  region: ColombiaRegion.andes,
  id: 'AV23',
  name: 'CD Bodega Externa PT Bucaraman'
}, {
  region: ColombiaRegion.andes,
  id: 'AV33',
  name: 'CD OL Aguachica'
}, {
  region: ColombiaRegion.andes,
  id: 'AV34',
  name: 'CD OL Barbosa'
}, {
  region: ColombiaRegion.andes,
  id: 'AV35',
  name: 'CD OL San Gil'
}, {
  region: ColombiaRegion.andes,
  id: 'AV37',
  name: 'CD OL Barrancabermeja'
}, {
  region: ColombiaRegion.andes,
  id: 'AV45',
  name: 'CD UCAVCOLOMBIAMEDELLIN'
}, {
  region: ColombiaRegion.andes,
  id: 'AV52',
  name: 'CD Medellín - Aranjuez'
}, {
  region: ColombiaRegion.andes,
  id: 'AV55',
  name: 'CD OL Girardota Marketplace'
}, {
  region: ColombiaRegion.andes,
  id: 'AV70',
  name: 'CD Urban Center Envigado'
}, {
  region: ColombiaRegion.andes,
  id: 'AV72',
  name: 'CD Urban Center Bucaramanga'
}, {
  region: ColombiaRegion.sur,
  id: 'AV01',
  name: 'CD Yumbo'
}, {
  region: ColombiaRegion.sur,
  id: 'AV15',
  name: 'CD Pasto'
}, {
  region: ColombiaRegion.sur,
  id: 'AV17',
  name: 'CD Girardot'
}, {
  region: ColombiaRegion.sur,
  id: 'AV18',
  name: 'CD Neiva'
}, {
  region: ColombiaRegion.sur,
  id: 'AV19',
  name: 'CD Villavicencio'
}, {
  region: ColombiaRegion.sur,
  id: 'AV24',
  name: 'CD Yopal'
}, {
  region: ColombiaRegion.sur,
  id: 'AV25',
  name: 'CD OL Honda'
}, {
  region: ColombiaRegion.sur,
  id: 'AV27',
  name: 'CD OL  Ibagué'
}, {
  region: ColombiaRegion.sur,
  id: 'AV28',
  name: 'CD OL Popayán'
}, {
  region: ColombiaRegion.sur,
  id: 'AV46',
  name: 'CALI'
}, {
  region: ColombiaRegion.sur,
  id: 'AV57',
  name: 'CD Tuluá'
}, {
  region: ColombiaRegion.sur,
  id: 'AV73',
  name: 'CD Urban Center Ibagué'
}, {
  region: ColombiaRegion.sur,
  id: 'AV75',
  name: 'CD Urban Center Cali'
}];
export var SKU_LIMITS_USERS = [{
  name: 'Luis Felipe Muñoz',
  email: 'LuisFelipe.MunozFlor@ab-inbev.com',
  access: ColombiaRegion.all
}, {
  name: 'Luis Felipe Muñoz',
  email: 'LMUNOZFL@gmodelo.com.mx',
  access: ColombiaRegion.all
}, {
  name: 'Alexandra Lopez',
  email: 'Alexandra.Lopez@ab-inbev.com',
  access: ColombiaRegion.all
}, {
  name: 'Juan David Conde',
  email: 'Juan.CondeB@ab-inbev.com',
  access: ColombiaRegion.all
}, {
  name: 'Juan David Conde',
  email: '38607647@gmodelo.com.mx',
  access: ColombiaRegion.all
}, {
  name: 'Giovanni Gutuierrez',
  email: 'Giovanni.Gutierrez@ab-inbev.com',
  access: ColombiaRegion.all
}, {
  name: 'FELIPE SALDARRIAGA CARVAJAL ',
  email: 'Felipe.Saldarriaga@ab-inbev.com',
  access: ColombiaRegion.all
}, {
  name: 'Hector Vargas',
  email: 'Hector.Vargas@ab-inbev.com',
  access: ColombiaRegion.andes
}, {
  name: 'Nataly Usuga Ramirez ',
  email: 'Nataly.Usuga-ext@ab-inbev.com',
  access: ColombiaRegion.andes
}, {
  name: 'Isela Amaya Quintero',
  email: 'isela.amaya@ab-inbev.com',
  access: ColombiaRegion.andes
}, {
  name: 'Daniela Agudelo',
  email: 'Daniela.Agudelo@ab-inbev.com',
  access: ColombiaRegion.andes
}, {
  name: 'Sergio Gonzalez',
  email: 'Sergio.Gonzalez.A@ab-inbev',
  access: ColombiaRegion.centro
}, {
  name: 'Sergio Gonzalez',
  email: '38607000@gmodelo.com.Mx',
  access: ColombiaRegion.centro
}, {
  name: 'Andrea Francis',
  email: 'Andrea.Francis@ab-inbev.com',
  access: ColombiaRegion.all
}, {
  name: '',
  email: 'Hvargas@gmodelo.com.mx',
  access: ColombiaRegion.andes
}, {
  name: '',
  email: 'cespitit@gmodelo.com.mx',
  access: ColombiaRegion.sur
}, {
  name: 'JHOJAN BELLO PENALOZA ',
  email: 'JHOJAN.BELLO@ab-inbev.com',
  access: ColombiaRegion.centro
}, {
  name: 'JHOJAN BELLO PENALOZA ',
  email: '38607000@gmodelo.com.mx',
  access: ColombiaRegion.centro
}, {
  name: 'Manuel Hidalgo',
  email: 'Manuel.Hidalgo@ab-inbev.com',
  access: ColombiaRegion.norte
}, {
  name: 'Daniel Diaz Pernett',
  email: 'Daniel.diazpernett@ab-inbev.com',
  access: ColombiaRegion.norte
}, {
  name: 'Daniel Diaz Pernett',
  email: 'daniel.diaz@modelo.gmodelo.com.mx',
  access: ColombiaRegion.norte
}, {
  name: 'Alejandro Vargas',
  email: 'Alejandro.Vargas@ab-inbev.com',
  access: ColombiaRegion.sur
}, {
  name: 'Alejandro Vargas',
  email: '38608493@gmodelo.com.mx',
  access: ColombiaRegion.sur
}, {
  name: 'Ana Maria Mejia Mancera ',
  email: 'Ana.Mejia@ab-inbev.com',
  access: ColombiaRegion.sur
}, {
  name: 'Ana Maria Mejia Mancera ',
  email: 'amejiama@gmodelo.com.mx',
  access: ColombiaRegion.sur
}, {
  name: 'Noam',
  email: 'noam@arena-ai.com',
  access: ColombiaRegion.all
}, {
  name: 'David Mack',
  email: 'david-test-colombia',
  access: ColombiaRegion.sur
}, {
  name: 'jorge uribe',
  email: 'jorge.uribe@ab-inbev.com',
  access: ColombiaRegion.all
}, {
  name: 'jorge uribe',
  email: 'jorge.uribe@gmodelo.com.mx',
  access: ColombiaRegion.all
}, {
  name: 'Nataly',
  email: 'nataly.usugan@modelo.gmodelo.com.mx',
  access: ColombiaRegion.andes
}, {
  name: 'Isela Amaya Quintero',
  email: 'isela.amaya@modelo.gmodelo.com.mx',
  access: ColombiaRegion.andes
}];