import { useContext } from 'react';
import { AuthContext } from '@/contexts';
import { useHasAccess } from '@/hooks';
import { ColombiaRegion, SKU_LIMITS_USERS } from '../constants/fromClient';
export var useHasAllAccess = function useHasAllAccess() {
  var isArena = useHasAccess(['arena-staff']);
  var _useContext = useContext(AuthContext),
    username = _useContext.username;
  var accessTableLookup = SKU_LIMITS_USERS.find(function (u) {
    return u.email.toLowerCase() === (username === null || username === void 0 ? void 0 : username.toLowerCase());
  });
  var hasAllAccess = isArena || (accessTableLookup === null || accessTableLookup === void 0 ? void 0 : accessTableLookup.access) === ColombiaRegion.all;
  return {
    hasAllAccess: hasAllAccess,
    accessTable: accessTableLookup === null || accessTableLookup === void 0 ? void 0 : accessTableLookup.access
  };
};