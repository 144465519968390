import { useEffect, useMemo } from 'react';
import { useGlobalValuesContext } from '@/contexts/GlobalValuesContext';
export var useGlobalCountry = function useGlobalCountry(defaultGlobalCountry, allowedCountries, setCountry) {
  var globalCountry = useGlobalValuesContext(function (state) {
    return state.globalCountry;
  });
  var isGlobalCountryLoaded = useGlobalValuesContext(function (state) {
    return state.isGlobalCountryLoaded;
  });
  var setGlobalCountry = useGlobalValuesContext(function (state) {
    return state.setGlobalCountry;
  });
  useEffect(function () {
    if (!(allowedCountries.length > 0)) return;
    var isIncludes = allowedCountries.includes(globalCountry);
    if (isIncludes) {
      setCountry(globalCountry);
    }
    if (!isIncludes && defaultGlobalCountry) {
      setCountry(defaultGlobalCountry);
    }
  }, [allowedCountries, globalCountry]);
  return useMemo(function () {
    return {
      setGlobalCountry: setGlobalCountry,
      isGlobalCountryLoaded: isGlobalCountryLoaded
    };
  }, [isGlobalCountryLoaded, setGlobalCountry]);
};