import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import thunk from 'redux-thunk';
import { rootReducer } from './rootReducer';
export var sentryReduxEnhancer = Sentry.createReduxEnhancer({});
var middleware = [thunk];
export var store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  enhancers: [sentryReduxEnhancer]
});