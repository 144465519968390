export var ModuleKey;
(function (ModuleKey) {
  ModuleKey["ALL"] = "ALL";
  ModuleKey["ALL_NON_PROMOS"] = "ALL_NON_PROMOS";
  ModuleKey["ALL_PROMOS"] = "ALL_PROMOS";
  ModuleKey["QUICK_ORDER"] = "QUICK_ORDER";
  ModuleKey["PROMOS_INTRODUCE"] = "PROMOS_INTRODUCE";
  ModuleKey["PROMOS_SUSTAIN"] = "PROMOS_SUSTAIN";
  ModuleKey["PROMOS_GENERATE_DEMAND"] = "PROMOS_GENERATE_DEMAND";
  ModuleKey["PROMOS_OPERATIONAL"] = "PROMOS_OPERATIONAL";
  ModuleKey["PROMOS_PRICE_TREE"] = "PROMOS_PRICE_TREE";
  ModuleKey["PROMOS_MARKET_SHARE"] = "PROMOS_MARKET_SHARE";
  ModuleKey["FORGOTTEN_ITEMS"] = "FORGOTTEN_ITEMS";
  ModuleKey["UPSELL"] = "UPSELL";
  ModuleKey["CROSS_SELL_UP_SELL"] = "CROSS_SELL_UP_SELL";
  ModuleKey["SKU_LIMITS"] = "SKU_LIMITS";
  ModuleKey["OUT_OF_STOCK_REPLACEMENT"] = "OUT_OF_STOCK_REPLACEMENT";
  ModuleKey["DEAL_SORT"] = "DEAL_SORT";
})(ModuleKey || (ModuleKey = {}));