export var USER_GROUPS = ['ab-one', 'arena-staff', 'bees-user', 'ben-e-keith', 'capital-eagle', 'carroll', 'eagle-rock', 'experiment-creators', 'h-cox-and-son', 'katcef', 'king-bev', 'module-settings-editors', 'non-abi-product-viewer', 'recs-actuals-viewers', 'romer', 'siberia-output-debugger-read', 'western-beverage', 'wil-fischer', 'working-distributors', 'zinc-distributors', 'default'];
export var AuthProviderKey;
(function (AuthProviderKey) {
  AuthProviderKey["AntarcticaSSO"] = "AntarcticaSSO";
  AuthProviderKey["UsernamePassword"] = "UsernamePassword";
  AuthProviderKey["Google"] = "Google";
  AuthProviderKey["Microsoft"] = "Microsoft";
  AuthProviderKey["ArenaStaff"] = "ArenaStaff";
})(AuthProviderKey || (AuthProviderKey = {}));
export var ClientBrandKey;
(function (ClientBrandKey) {
  ClientBrandKey["None"] = "None";
  ClientBrandKey["BEEs"] = "BEEs";
  ClientBrandKey["Fornax"] = "Fornax";
  ClientBrandKey["TBO"] = "TBO";
})(ClientBrandKey || (ClientBrandKey = {}));
export var BEEsRecommendationsBackend;
(function (BEEsRecommendationsBackend) {
  BEEsRecommendationsBackend["Siberia"] = "siberia";
  BEEsRecommendationsBackend["Antarctica"] = "antarctica";
})(BEEsRecommendationsBackend || (BEEsRecommendationsBackend = {}));
export var TableServiceMajorPartition;
(function (TableServiceMajorPartition) {
  TableServiceMajorPartition["Country"] = "country";
  TableServiceMajorPartition["Wholesaler"] = "wholesaler";
})(TableServiceMajorPartition || (TableServiceMajorPartition = {}));
export var StatusTab;
(function (StatusTab) {
  StatusTab["Modules"] = "modules";
  StatusTab["ModelHealth"] = "model-health";
  StatusTab["Deployment"] = "deployment";
})(StatusTab || (StatusTab = {}));
export var StatusTabWithoutModules;
(function (StatusTabWithoutModules) {
  StatusTabWithoutModules["OutputDebugger"] = "output-debugger";
  StatusTabWithoutModules["ModelHealth"] = "model-health";
  StatusTabWithoutModules["Deployment"] = "deployment";
})(StatusTabWithoutModules || (StatusTabWithoutModules = {}));
export var CreateExperimentTab;
(function (CreateExperimentTab) {
  CreateExperimentTab["Settings"] = "settings";
  CreateExperimentTab["Experiments"] = "experiments";
  CreateExperimentTab["RecentChanges"] = "recent-changes";
})(CreateExperimentTab || (CreateExperimentTab = {}));