export var DeploymentKey;
(function (DeploymentKey) {
  DeploymentKey["atlas"] = "atlas";
  DeploymentKey["antarctica"] = "antarctica";
  DeploymentKey["siberia"] = "siberia";
  DeploymentKey["shelf"] = "shelf";
  DeploymentKey["synapse"] = "synapse";
  DeploymentKey["demo"] = "demo";
  DeploymentKey["cygnus"] = "cygnus";
  DeploymentKey["triton"] = "triton";
  DeploymentKey["demoHelm"] = "demoHelm";
  DeploymentKey["hydra"] = "hydra";
})(DeploymentKey || (DeploymentKey = {}));