export function buildOrderBy(orderByField, orderByType) {
  return [{
    column: orderByField,
    direction: orderByType === 'asc' ? 'ASC' : 'DESC'
  }];
}
export function buildQueryRequest(params) {
  var orderByField = params.orderByField,
    orderByType = params.orderByType,
    where = params.where,
    limit = params.limit,
    offset = params.offset,
    select = params.select,
    distinct = params.distinct,
    aggregate = params.aggregate;
  var orderBy = orderByField && orderByType ? buildOrderBy(orderByField, orderByType) : undefined;
  return {
    order_by: orderBy,
    where: where === null || where === void 0 ? void 0 : where.get(),
    limit: limit,
    offset: offset,
    select: select,
    distinct: distinct,
    aggregate: aggregate === null || aggregate === void 0 ? void 0 : aggregate.get()
  };
}