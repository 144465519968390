import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
export var useScrollPosition = function useScrollPosition() {
  var _useState = useState(window.scrollY),
    _useState2 = _slicedToArray(_useState, 2),
    scrollX = _useState2[0],
    setScrollX = _useState2[1];
  var _useState3 = useState(window.scrollY),
    _useState4 = _slicedToArray(_useState3, 2),
    scrollY = _useState4[0],
    setScrollY = _useState4[1];
  useEffect(function () {
    var listener = function listener() {
      setScrollX(window.scrollX);
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', listener);
    listener();
    return function () {
      return window.removeEventListener('scroll', listener);
    };
  }, []);
  return {
    scrollX: scrollX,
    scrollY: scrollY
  };
};