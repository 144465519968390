import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useLayoutEffect, useState } from 'react';
export function useWindowSize() {
  var _useState = useState([0, 0]),
    _useState2 = _slicedToArray(_useState, 2),
    size = _useState2[0],
    setSize = _useState2[1];
  useLayoutEffect(function () {
    var timer;
    function updateSize() {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        setSize([window.innerWidth, window.innerHeight]);
      }, 50);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return function () {
      if (timer) {
        clearTimeout(timer);
      }
      window.removeEventListener('resize', updateSize);
    };
  }, []);
  return size;
}