import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _BG_COLOR_TO_TEXT_COL;
import Color from 'color';
export var COLOR_BOLD_BLUE = '#123FDF';
export var COLOR_GREEN = '#54E85A';
export var COLOR_DARK_GREEN = '#08AC0E';
export var COLOR_PURPLE = '#8042E3';
export var COLOR_YELLOW = '#EBB938';
export var COLOR_ORANGE = '#F07039';
export var COLOR_RED = '#DF2960';
export var COLOR_MAGENTA = '#C839DF';
export var COLOR_POWDER_BLUE = '#1D7AE8';
export var COLOR_TROPICAL_BLUE = '#0EBFF6';
export var COLOR_TEAL = '#35DFD5';
export var COLOR_NAVY = '#08103A';
export var COLOR_NAVY_SOLID = '#B1B5C1';
export var COLOR_SPIRO_DISCO_BALL = '#0EBFF7';
export var COLOR_BRANDEIS_BLUE = '#0067FF';
export var COLOR_POWDER_GREEN = '#54E85A';
export var COLOR_TROPICAL_GREEN = '#C1E854';
export var COLOR_PURPLE2 = '#EFAFF8';
export var COLOR_PURPLE3 = '#8F14A3';
export var COLOR_PURPLE4 = '#C939DF';
export var COLOR_DARK_PURPLE = '#51015c';
export var COLOR_BLACK = '#000000';
export var COLOR_WHITE = '#ffffff';
export var COLOR_DARKER_WHITE = '#a1a1a1';
export var COLOR_PRIMARY_DARK = '#c8c8c8';
export var COLOR_BG_DARK = '#181d24';
export var COLOR_BEES = '#FFFF00';
export var RED_HUES = [COLOR_RED, '#f54242', '#782424', '#d94b27', '#d92745'];
export var getMixedColors = function getMixedColors(mainColor, mixinColor) {
  var weight = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return Color(mainColor).mix(Color(mixinColor), weight);
};
export var getPrimaryHexColor = function getPrimaryHexColor() {
  var alpha = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  var theme = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'light';
  var mainColor = theme === 'light' ? COLOR_NAVY : COLOR_PRIMARY_DARK;
  var mixinColor = theme === 'light' ? COLOR_WHITE : COLOR_BG_DARK;
  var mixedColor = getMixedColors(mainColor, mixinColor, 1 - alpha);
  return mixedColor.hex();
};
export var COLOR_GRAY = getPrimaryHexColor(0.2);
export var BG_COLOR_TO_TEXT_COLOR = (_BG_COLOR_TO_TEXT_COL = {}, _defineProperty(_BG_COLOR_TO_TEXT_COL, COLOR_YELLOW, COLOR_NAVY), _defineProperty(_BG_COLOR_TO_TEXT_COL, COLOR_RED, COLOR_WHITE), _defineProperty(_BG_COLOR_TO_TEXT_COL, COLOR_ORANGE, COLOR_NAVY), _defineProperty(_BG_COLOR_TO_TEXT_COL, COLOR_GREEN, COLOR_NAVY), _defineProperty(_BG_COLOR_TO_TEXT_COL, COLOR_GRAY, getPrimaryHexColor(1)), _defineProperty(_BG_COLOR_TO_TEXT_COL, COLOR_TEAL, COLOR_NAVY), _defineProperty(_BG_COLOR_TO_TEXT_COL, COLOR_TROPICAL_BLUE, COLOR_NAVY), _defineProperty(_BG_COLOR_TO_TEXT_COL, COLOR_PURPLE, COLOR_WHITE), _BG_COLOR_TO_TEXT_COL);
export var CHART_COLORS = [COLOR_BOLD_BLUE, COLOR_TROPICAL_BLUE, COLOR_TEAL, COLOR_MAGENTA, COLOR_PURPLE, COLOR_RED, COLOR_ORANGE, COLOR_YELLOW, COLOR_GREEN, COLOR_DARK_GREEN];
export var RAINBOW_COLORS = [COLOR_BOLD_BLUE, COLOR_POWDER_BLUE, COLOR_TROPICAL_BLUE, COLOR_TEAL, COLOR_GREEN, COLOR_YELLOW, COLOR_ORANGE, COLOR_RED, COLOR_MAGENTA, COLOR_PURPLE];
export var getRGBA = function getRGBA(color) {
  var alpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  if (alpha === 1) {
    return color;
  }
  var rgb = Color(color).rgb().array().join(',');
  return "rgba(".concat(rgb, ",").concat(alpha, ")");
};
export var getRGBasArray = function getRGBasArray(color) {
  return Color(color).rgb().array();
};
export var getPrimaryColor = function getPrimaryColor() {
  var alpha = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return "rgba(var(--primary-color), ".concat(alpha, ")");
};
export var getContrastColor = function getContrastColor() {
  var alpha = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return "rgba(var(--contrast-color), ".concat(alpha, ")");
};
export var getShadowColor = function getShadowColor() {
  return 'var(--shadow-color)';
};
export var getModalOverlayColor = function getModalOverlayColor() {
  var isDarkMode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return "rgba(var(".concat(isDarkMode ? '--bg-color' : '--primary-color', "), ").concat(isDarkMode ? 0.7 : 0.4, ")");
};
export var getColorByIndex = function getColorByIndex(colors, index) {
  return colors[index % colors.length];
};
export var getChartColor = function getChartColor(data, i) {
  var exclude = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var filteredColors = CHART_COLORS.filter(function (c) {
    return !exclude.includes(c);
  });
  return getColorByIndex(filteredColors, i);
};
export var getGradientBarColor = function getGradientBarColor(data, index) {
  var color = getChartColor(data, index);
  return [{
    offset: 0,
    color: getRGBA(color, 0.8)
  }, {
    offset: 100,
    color: getRGBA(color, 1)
  }];
};