import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
var _objectSpread2;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import _ from 'lodash';
import { countries } from 'typed-countries';
export var ALL_COUNTRY = 'ALL';
export var GLOBAL_COUNTRY = 'GLOBAL';
export var WORLD_COUNTRY = 'WORLD';
export function sortCountryCodeByName(codes) {
  return _.sortBy(codes, function (code) {
    var _countries$find;
    return (_countries$find = countries.find(function (c) {
      return c.iso === code;
    })) === null || _countries$find === void 0 ? void 0 : _countries$find.name;
  });
}
export var PROMO_COUNTRY_CODES = ['BR', 'CO', 'AR', 'DO'];
export var ALL_COUNTRIES = sortCountryCodeByName(countries.map(function (c) {
  return c.iso;
}));
export var CAMO_COUNTRY_CODES = ['DE', 'SG', 'GB', 'AU', 'TR', 'IE', 'JP', 'IT', 'FR', 'DK', 'HU', 'ES'];
export var ANTARCTICA_COUNTRY_CODES = ['DO', 'BR', 'CA', 'CO', 'HN', 'MX', 'ZA', 'AR', 'PA', 'PE', 'PY', 'EC', 'SV', 'TZ', 'UY', 'UG', 'GB', 'KR'];
export var SIBERIA_COUNTRY_CODES = ['US'];
export var ALL_COUNTRY_CODES = [].concat(_toConsumableArray(countries.map(function (i) {
  return i.iso;
})), _toConsumableArray(WORLD_COUNTRY));
export var POC_COUNTRY_CODES = ['DO', 'BR', 'CO', 'MX', 'ZA', 'AR', 'PA', 'PE', 'EC'];
export var GALILEO_COUNTRY_CODES = ['DO', 'BR', 'CO', 'MX', 'ZA', 'AR', 'PA', 'PE', 'EC'];
export var EXPERIMENTS_COUNTRY_CODES = [].concat(ANTARCTICA_COUNTRY_CODES, [ALL_COUNTRY]);
export var VALKYRIE_COUNTRY_CODES = [ALL_COUNTRY, 'DO', 'BR', 'CO', 'MX', 'ZA', 'AR', 'PA', 'PE', 'PY', 'EC', 'SV', 'UY'];
export var VALKYRIE_DEMO_COUNTRY_CODES = [ALL_COUNTRY, 'BR'];
export var ARENA_MODEL_COUNTRY_CODES = ['DO', 'BR', 'CO', 'ZA', 'AR'];
export var NON_ARENA_MODEL_COUNTRY_CODES = _.without.apply(_, [ANTARCTICA_COUNTRY_CODES].concat(ARENA_MODEL_COUNTRY_CODES));
export var COUNTRY_NAMES = _objectSpread(_objectSpread((_objectSpread2 = {}, _defineProperty(_objectSpread2, ALL_COUNTRY, 'All countries'), _defineProperty(_objectSpread2, GLOBAL_COUNTRY, 'Global'), _defineProperty(_objectSpread2, WORLD_COUNTRY, 'World'), _objectSpread2), _(countries).map(function (country) {
  var parsedName = country.name.replace(/(, .+)/, '');
  var name = _.filter(countries, function (v) {
    return v.name.includes(parsedName);
  }).length > 1 ? country.name : parsedName;
  return [country.iso, name];
}).fromPairs().value()), {}, {
  KR: 'Korea'
});
export var VALKYRIE_COUNTRY_NAMES = _objectSpread({}, COUNTRY_NAMES);
export var COUNTRY_NAMES_ARRAY = Object.values(COUNTRY_NAMES);
export var COUNTRY_CURRENCY_NAME_MAP = {
  BR: 'Real',
  CO: 'COP',
  DO: 'DOP'
};
export var COUNTRY_CURRENCY_PREFIX_MAP = {
  BR: 'R$',
  CO: '$',
  DO: 'RD$'
};
export var StatesOfBrazil = [{
  label: 'Acre',
  value: 'AC'
}, {
  label: 'Alagoas',
  value: 'AL'
}, {
  label: 'Amapá',
  value: 'AP'
}, {
  label: 'Amazonas',
  value: 'AM'
}, {
  label: 'Bahia',
  value: 'BA'
}, {
  label: 'Ceará',
  value: 'CE'
}, {
  label: 'Distrito Federal',
  value: 'DF'
}, {
  label: 'Espírito Santo',
  value: 'ES'
}, {
  label: 'Goiás',
  value: 'GO'
}, {
  label: 'Maranhão',
  value: 'MA'
}, {
  label: 'Mato Grosso',
  value: 'MT'
}, {
  label: 'Mato Grosso do Sul',
  value: 'MS'
}, {
  label: 'Minas Gerais',
  value: 'MG'
}, {
  label: 'Pará',
  value: 'PA'
}, {
  label: 'Paraíba',
  value: 'PB'
}, {
  label: 'Paraná',
  value: 'PR'
}, {
  label: 'Pernambuco',
  value: 'PE'
}, {
  label: 'Piauí',
  value: 'PI'
}, {
  label: 'Rio de Janeiro',
  value: 'RJ'
}, {
  label: 'Rio Grande do Norte',
  value: 'RN'
}, {
  label: 'Rio Grande do Sul',
  value: 'RS'
}, {
  label: 'Rondônia',
  value: 'RO'
}, {
  label: 'Roraima',
  value: 'RR'
}, {
  label: 'Santa Catarina',
  value: 'SC'
}, {
  label: 'São Paulo',
  value: 'SP'
}, {
  label: 'Sergipe',
  value: 'SE'
}, {
  label: 'Tocantins',
  value: 'TO'
}];