import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _ from 'lodash';
import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';
export function useUserParam(key, initialValue) {
  var _useLocalStorage = useLocalStorage(key, initialValue),
    _useLocalStorage2 = _slicedToArray(_useLocalStorage, 3),
    value = _useLocalStorage2[0],
    setValue = _useLocalStorage2[1],
    removeValue = _useLocalStorage2[2];
  var setUserParam = useCallback(function (val) {
    if (_.isNil(val)) {
      removeValue();
    } else {
      setValue(val);
    }
  }, [setValue]);
  return [value !== null && value !== void 0 ? value : undefined, setUserParam];
}