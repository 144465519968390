import moment from 'moment';
import { useContext, useMemo } from 'react';
import { AuthContext } from '@/contexts';
export var usePride = function usePride() {
  var _useContext = useContext(AuthContext),
    userGroups = _useContext.userGroups;
  return useMemo(function () {
    var isCorrectDate = moment().month() === 5;
    return isCorrectDate;
  }, [userGroups]);
};