import { useEffect, useRef } from 'react';
export var useComponentVisible = function useComponentVisible(hideComponent, additionalEl) {
  var ref = useRef(null);
  var handleHideDropdown = function handleHideDropdown(event) {
    if (event.key === 'Escape') {
      hideComponent();
    }
  };
  var handleClickOutside = function handleClickOutside(event) {
    var _ref$current;
    if (!((_ref$current = ref.current) !== null && _ref$current !== void 0 && _ref$current.contains(event.target)) && !(additionalEl !== null && additionalEl !== void 0 && additionalEl.contains(event.target))) {
      hideComponent();
    }
  };
  useEffect(function () {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return function () {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, additionalEl]);
  return ref;
};