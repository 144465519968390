import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _ENV_KEY_NAME_MAP, _ENV_KEYS_MAP;
import { COLOR_MAGENTA, COLOR_TROPICAL_BLUE } from './colors';
export var ENV_PROD_KEY = 'PROD';
export var ENV_UAT_KEY = 'UAT';
export var ENV_KEYS = [ENV_PROD_KEY, ENV_UAT_KEY];
export var ENV_KEY_NAME_MAP = (_ENV_KEY_NAME_MAP = {}, _defineProperty(_ENV_KEY_NAME_MAP, ENV_PROD_KEY, 'master'), _defineProperty(_ENV_KEY_NAME_MAP, ENV_UAT_KEY, 'develop'), _ENV_KEY_NAME_MAP);
export var ENV_KEY_BRANCH_MAP = {
  master: ENV_PROD_KEY,
  develop: ENV_UAT_KEY
};
export var ENV_KEYS_MAP = (_ENV_KEYS_MAP = {}, _defineProperty(_ENV_KEYS_MAP, ENV_PROD_KEY, 'Production'), _defineProperty(_ENV_KEYS_MAP, ENV_UAT_KEY, 'UAT'), _ENV_KEYS_MAP);
export var ENV_KEY_COLOR_MAP = {
  PROD: COLOR_TROPICAL_BLUE,
  UAT: COLOR_MAGENTA
};