import _ from 'lodash';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { StringFormatter } from '../utils';
export var useQueryString = function useQueryString() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _config$slugify = config.slugify,
    slugify = _config$slugify === void 0 ? false : _config$slugify;
  var history = useHistory();
  var location = useLocation();
  var urlSearchParams = new URLSearchParams(location.search);
  var queryStringObject = {};
  urlSearchParams.forEach(function (value, key) {
    queryStringObject[key] = slugify ? StringFormatter.toUnslugString(value) : value;
  });
  var updateQueryString = useCallback(function () {
    var newQueryStringObject = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var updateConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var _updateConfig$replace = updateConfig.replace,
      replace = _updateConfig$replace === void 0 ? false : _updateConfig$replace,
      _updateConfig$slugify = updateConfig.slugify,
      updateSlugify = _updateConfig$slugify === void 0 ? false : _updateConfig$slugify;
    var searchParams = new URLSearchParams(replace ? '' : location.search);
    var parsedNewQueryStringObject = updateSlugify ? _.mapValues(newQueryStringObject, StringFormatter.toSlugString) : newQueryStringObject;
    _.forEach(parsedNewQueryStringObject, function (value, key) {
      if (_.isNil(value)) {
        searchParams["delete"](key);
        return;
      }
      searchParams.set(key, value);
    });
    history.push({
      search: searchParams.toString()
    });
    location.search = searchParams.toString();
  }, [history, location]);
  return {
    queryStringObject: queryStringObject,
    queryString: location.search,
    pathname: location.pathname,
    updateQueryString: updateQueryString
  };
};