import { useEffect } from 'react';
export var useKeyboardNavigation = function useKeyboardNavigation(_ref) {
  var onLeft = _ref.onLeft,
    onRight = _ref.onRight;
  useEffect(function () {
    if (!onLeft && !onRight) return undefined;
    var eventFn = function eventFn(e) {
      if (e.key === 'ArrowLeft') {
        onLeft === null || onLeft === void 0 ? void 0 : onLeft();
      }
      if (e.key === 'ArrowRight') {
        onRight === null || onRight === void 0 ? void 0 : onRight();
      }
    };
    document.addEventListener('keydown', eventFn);
    return function () {
      return document.removeEventListener('keydown', eventFn);
    };
  }, [onLeft, onRight]);
};