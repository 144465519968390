import _ from 'lodash';
import moment from 'moment';
import { CURRENT_YEAR } from '@/constants';
export var generateRandomDate = function generateRandomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toISOString();
};
export var generateRandomDates = function generateRandomDates(count, format) {
  return _.range(count).map(function () {
    return moment(generateRandomDate(new Date(CURRENT_YEAR, 0, 1), new Date())).format(format);
  });
};