import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import _ from 'lodash';
import { useMemo } from 'react';
import { getPrimaryHexColor } from '@/constants';
import { useTheme } from './useTheme';
export var useChartOptions = function useChartOptions(props, extras) {
  var _ref = props !== null && props !== void 0 ? props : {},
    stacked = _ref.stacked,
    plugins = _ref.plugins,
    yLabel = _ref.yLabel,
    xLabel = _ref.xLabel,
    yScaleMax = _ref.yScaleMax,
    yAxisTickCallback = _ref.yAxisTickCallback,
    groupedTooltip = _ref.groupedTooltip,
    additionalScales = _ref.additionalScales,
    aspectRatio = _ref.aspectRatio;
  var theme = useTheme();
  var reversedTheme = theme === 'light' ? 'dark' : 'light';
  return useMemo(function () {
    var interaction = {
      intersect: false,
      mode: groupedTooltip ? 'index' : undefined
    };
    return _.merge({
      aspectRatio: aspectRatio !== null && aspectRatio !== void 0 ? aspectRatio : 2,
      scales: _objectSpread({
        x: {
          grid: {
            display: false
          },
          stacked: stacked,
          title: {
            text: xLabel,
            display: !!xLabel
          }
        },
        y: {
          grid: {
            color: getPrimaryHexColor(theme === 'light' ? 0.05 : 0.15, theme)
          },
          stacked: stacked,
          title: {
            text: yLabel,
            display: !!yLabel
          },
          max: yScaleMax,
          ticks: {
            callback: yAxisTickCallback
          }
        }
      }, additionalScales),
      plugins: _.defaultsDeep(plugins, {
        tooltip: {
          backgroundColor: getPrimaryHexColor(1, theme),
          titleColor: getPrimaryHexColor(1, reversedTheme),
          bodyColor: getPrimaryHexColor(1, reversedTheme),
          callbacks: {
            label: yAxisTickCallback ? function (tooltipItem) {
              return "".concat(tooltipItem.dataset.label, ": ").concat(yAxisTickCallback(tooltipItem.raw.y));
            } : undefined
          }
        }
      }),
      spanGaps: true,
      interaction: interaction
    }, extras !== null && extras !== void 0 ? extras : {});
  }, [theme, stacked, plugins, yLabel, yScaleMax, yAxisTickCallback, groupedTooltip, additionalScales, extras]);
};