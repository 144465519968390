export var DEFAULT_ALLOCATION_PCT = 30;
export var DEFAULT_SKU_CONFIG_BY_COUNTRY = {
  BR: undefined,
  CO: {
    minQuantity: 1,
    maxQuantity: 999999,
    minDiscountPct: 0.5
  },
  DO: {
    minQuantity: 1,
    maxQuantity: 999999
  }
};
export var PRODUCT_NAME_COL = {
  Header: 'Product',
  accessor: 'productName'
};
export var MIN_QTY_COL = {
  Header: 'Min Qty',
  accessor: 'minQuantity'
};
export var MAX_QTY_COL = {
  Header: 'Max Qty',
  accessor: 'maxQuantity'
};
export var MIN_DISCOUNT_COL = {
  Header: 'Min Discount %',
  accessor: 'minDiscountPct'
};
export var MAX_DISCOUNT_COL = {
  Header: 'Max Discount %',
  accessor: 'maxDiscountPct'
};
export var ALLOCATION_COL = {
  Header: 'Allocation %',
  accessor: 'allocationPct'
};
export var VALID_COL = {
  Header: 'Valid',
  accessor: 'valid'
};
export var POC_RETAIN_OPTION = 'retainPOC';
export var SKU_RETAIN_OPTION = 'retainSKU';