export var Arm;
(function (Arm) {
  Arm["beesApp"] = "BEES App";
  Arm["braze"] = "Braze";
  Arm["sendGrid"] = "SendGrid";
  Arm["twilio"] = "Twilio";
  Arm["whatsApp"] = "WhatsApp";
  Arm["slack"] = "Slack";
})(Arm || (Arm = {}));
export var Sensor;
(function (Sensor) {
  Sensor["segment"] = "Segment";
  Sensor["promofusion"] = "Promofusion";
  Sensor["catalogApi"] = "Catalog API";
  Sensor["dealsApi"] = "Deals API";
  Sensor["weather"] = "Weather";
  Sensor["holidays"] = "Holidays";
  Sensor["sportsEvents"] = "Sports Events";
})(Sensor || (Sensor = {}));