import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
export var useDarkMode = function useDarkMode() {
  var _useState = useState('light'),
    _useState2 = _slicedToArray(_useState, 2),
    mode = _useState2[0],
    setMode = _useState2[1];
  useEffect(function () {
    var _window2, _window2$matchMedia;
    var fn = function fn() {
      var _window, _window$matchMedia;
      if ((_window = window) !== null && _window !== void 0 && (_window$matchMedia = _window.matchMedia('(prefers-color-scheme: dark)')) !== null && _window$matchMedia !== void 0 && _window$matchMedia.matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    };
    fn();
    (_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$matchMedia = _window2.matchMedia('(prefers-color-scheme: dark)')) === null || _window2$matchMedia === void 0 ? void 0 : _window2$matchMedia.addEventListener('change', fn);
    return function () {
      var _window3, _window3$matchMedia;
      (_window3 = window) === null || _window3 === void 0 ? void 0 : (_window3$matchMedia = _window3.matchMedia('(prefers-color-scheme: dark)')) === null || _window3$matchMedia === void 0 ? void 0 : _window3$matchMedia.removeEventListener('change', fn);
    };
  }, []);
  return mode;
};