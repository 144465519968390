import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _ from 'lodash';
import { BUILD_CONFIG, ModuleKey } from '@/buildConfig';
import { COLOR_GREEN, COLOR_NAVY, COLOR_ORANGE, COLOR_POWDER_BLUE, COLOR_PURPLE, COLOR_RED, COLOR_TROPICAL_BLUE, COLOR_WHITE, getContrastColor, getPrimaryColor } from './colors';
export var MODULE_NAME_MAP = {
  ALL: 'All modules',
  ALL_NON_PROMOS: 'All non-promos',
  ALL_PROMOS: 'All promos',
  QUICK_ORDER: 'Suggested order',
  FORGOTTEN_ITEMS: 'Forgotten items',
  OUT_OF_STOCK_REPLACEMENT: 'Out of Stock',
  PROMOS_INTRODUCE: 'Promos introduce',
  PROMOS_SUSTAIN: 'Promos sustain',
  PROMOS_GENERATE_DEMAND: 'Promos generate demand',
  PROMOS_OPERATIONAL: 'Promos operational',
  PROMOS_PRICE_TREE: 'Promos price tree',
  PROMOS_MARKET_SHARE: 'Promos market share',
  UPSELL: 'Upsell',
  CROSS_SELL_UP_SELL: 'Upsell',
  SKU_LIMITS: 'Smart Quotas',
  DEAL_SORT: 'Deal Sorting'
};
export var MODULE_ORDER_MAP = {
  ALL: 0,
  ALL_NON_PROMOS: 1,
  ALL_PROMOS: 2,
  QUICK_ORDER: 3,
  UPSELL: 4,
  CROSS_SELL_UP_SELL: 4,
  FORGOTTEN_ITEMS: 5,
  OUT_OF_STOCK_REPLACEMENT: 6,
  DEAL_SORT: 7,
  SKU_LIMITS: 8,
  PROMOS_INTRODUCE: 9,
  PROMOS_SUSTAIN: 10,
  PROMOS_GENERATE_DEMAND: 11,
  PROMOS_OPERATIONAL: 12,
  PROMOS_PRICE_TREE: 13,
  PROMOS_MARKET_SHARE: 14
};
export var EXPERIMENTS_MODULES_WITHOUT_ALL_KEYS = BUILD_CONFIG.experiments.modules;
export var MODULES_ONLY_ALL_KEYS = [ModuleKey.ALL].concat(_toConsumableArray(_.some(BUILD_CONFIG.experiments.modules, function (key) {
  return key.includes('PROMO');
}) ? [ModuleKey.ALL_PROMOS, ModuleKey.ALL_NON_PROMOS] : []));
export var EXPERIMENTS_MODULES_KEYS = [].concat(_toConsumableArray(MODULES_ONLY_ALL_KEYS), _toConsumableArray(EXPERIMENTS_MODULES_WITHOUT_ALL_KEYS));
export var EXPERIMENTS_MODULES_NON_PROMO_KEYS = _.filter(EXPERIMENTS_MODULES_WITHOUT_ALL_KEYS, function (key) {
  return !key.includes('PROMO');
});
export var EXPERIMENTS_MODULES_PROMO_KEYS = _.filter(EXPERIMENTS_MODULES_WITHOUT_ALL_KEYS, function (key) {
  return key.includes('PROMO');
});
export var OUTPUT_DEBUGGER_MODULE_KEYS = ['QUICK_ORDER', 'FORGOTTEN_ITEMS', 'CROSS_SELL_UP_SELL'];
export var MODULE_COLOR_MAP = {
  QUICK_ORDER: COLOR_PURPLE,
  UPSELL: COLOR_RED,
  CROSS_SELL_UP_SELL: COLOR_RED,
  FORGOTTEN_ITEMS: COLOR_GREEN,
  PROMOS_INTRODUCE: getPrimaryColor(1),
  PROMOS_SUSTAIN: getPrimaryColor(1),
  PROMOS_GENERATE_DEMAND: getPrimaryColor(1),
  PROMOS_OPERATIONAL: getPrimaryColor(1),
  PROMOS_PRICE_TREE: getPrimaryColor(1),
  PROMOS_MARKET_SHARE: getPrimaryColor(1),
  PROMOS: getPrimaryColor(1),
  promos: getPrimaryColor(1),
  SKU_LIMITS: getPrimaryColor(1),
  OUT_OF_STOCK_REPLACEMENT: COLOR_POWDER_BLUE,
  DEAL_SORT: COLOR_ORANGE
};
export var MODULE_COLOR_MAP_VALKYRIE = {
  PROMOS_INTRODUCE: COLOR_TROPICAL_BLUE,
  PROMOS_SUSTAIN: COLOR_POWDER_BLUE,
  PROMOS_GENERATE_DEMAND: COLOR_GREEN,
  PROMOS_OPERATIONAL: COLOR_ORANGE
};
export var MODULE_TEXT_COLOR_MAP = {
  QUICK_ORDER: COLOR_WHITE,
  UPSELL: COLOR_WHITE,
  CROSS_SELL_UP_SELL: COLOR_WHITE,
  FORGOTTEN_ITEMS: COLOR_NAVY,
  PROMOS_INTRODUCE: getContrastColor(1),
  PROMOS_SUSTAIN: getContrastColor(1),
  PROMOS_GENERATE_DEMAND: getContrastColor(1),
  PROMOS_OPERATIONAL: getContrastColor(1),
  PROMOS_PRICE_TREE: getContrastColor(1),
  PROMOS_MARKET_SHARE: getContrastColor(1),
  SKU_LIMITS: getContrastColor(1),
  OUT_OF_STOCK_REPLACEMENT: COLOR_NAVY,
  DEAL_SORT: COLOR_NAVY
};
export var UPSELL_ANOTHER = {
  UPSELL: 'CROSS_SELL_UP_SELL',
  CROSS_SELL_UP_SELL: 'UPSELL'
};
export var UPSELL_KEYS = ['UPSELL', 'CROSS_SELL_UP_SELL'];
export var checkModuleByUpsell = function checkModuleByUpsell(module) {
  if (_.includes(UPSELL_KEYS, module)) {
    return UPSELL_ANOTHER[module];
  }
  return module;
};
export function generateModuleOptionsFromKeys(keys, labelFormatter) {
  return keys.map(function (key) {
    var _MODULE_NAME_MAP$key;
    var moduleName = (_MODULE_NAME_MAP$key = MODULE_NAME_MAP[key]) !== null && _MODULE_NAME_MAP$key !== void 0 ? _MODULE_NAME_MAP$key : key;
    var label = labelFormatter ? labelFormatter(moduleName) : moduleName;
    return {
      value: key,
      label: label,
      divider: key === ModuleKey.ALL_PROMOS || key === ModuleKey.ALL
    };
  }).sort(function (a, b) {
    return MODULE_ORDER_MAP[a.value] - MODULE_ORDER_MAP[b.value];
  });
}
export var PROMO_MODULE_KEYS = ['PROMOS_GENERATE_DEMAND', 'PROMOS_INTRODUCE', 'PROMOS_OPERATIONAL', 'PROMOS_SUSTAIN', 'PROMOS_MARKET_SHARE'];
export var EXPERIMENTS_MODULE_OPTIONS = generateModuleOptionsFromKeys(EXPERIMENTS_MODULES_KEYS);
export var OUTPUT_DEBUGGER_MODULE_OPTIONS = generateModuleOptionsFromKeys(OUTPUT_DEBUGGER_MODULE_KEYS);
export var VALKYRIE_PROMOTION_ACTIVITY_COLORS = {
  Active: COLOR_GREEN,
  Issue: COLOR_ORANGE,
  Complete: COLOR_POWDER_BLUE,
  Scheduled: COLOR_PURPLE
};