export var NavBarItemKey;
(function (NavBarItemKey) {
  NavBarItemKey["AntarcticaDashboard"] = "AntarcticaDashboard";
  NavBarItemKey["SiberiaDashboard"] = "SiberiaDashboard";
  NavBarItemKey["SynapseDashboard"] = "SynapseDashboard";
  NavBarItemKey["CygnusDashboard"] = "CygnusDashboard";
  NavBarItemKey["TritonDashboard"] = "TritonDashboard";
  NavBarItemKey["HelmV2Dashboard"] = "DemoHelmDashboard";
  NavBarItemKey["Experiments"] = "Experiments";
  NavBarItemKey["Promotions"] = "Promotions";
  NavBarItemKey["POCBrowser"] = "POCBrowser";
  NavBarItemKey["ModuleSettings"] = "ModuleSettings";
  NavBarItemKey["Documentation"] = "Documentation";
  NavBarItemKey["Shelf"] = "Shelf";
  NavBarItemKey["PromoMAX"] = "PromoMAX";
  NavBarItemKey["PromoMAXDemo"] = "PromoMAXDemo";
  NavBarItemKey["Inspect"] = "Inspect";
  NavBarItemKey["Status"] = "Status";
  NavBarItemKey["Recommendations"] = "Recommendations";
  NavBarItemKey["Help"] = "Help";
  NavBarItemKey["Developer"] = "Developer";
  NavBarItemKey["Helm"] = "Helm";
  NavBarItemKey["MobileStore"] = "MobileStore";
  NavBarItemKey["Integrations"] = "Integrations";
  NavBarItemKey["SalesOpportunities"] = "SalesOpportunities";
  NavBarItemKey["Skynet"] = "Skynet";
  NavBarItemKey["UpsellDeepDive"] = "UpsellDeepDive";
  NavBarItemKey["InventoryReplenishment"] = "InventoryManagement";
  NavBarItemKey["HelmV2"] = "HelmV2";
  NavBarItemKey["DemoSettings"] = "DemoSettings";
  NavBarItemKey["DemoRealtimeActions"] = "DemoRealtimeActions";
  NavBarItemKey["SmartQuotas"] = "SmartQuotas";
  NavBarItemKey["WikiArena"] = "WikiArena";
  NavBarItemKey["Console"] = "Console";
})(NavBarItemKey || (NavBarItemKey = {}));
export var ALWAYS_AVAILABLE_NAV_BAR_ITEMS = [NavBarItemKey.Developer];