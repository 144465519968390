import { createTheme } from '@mui/material';
import _ from 'lodash';
import { COLOR_BOLD_BLUE, COLOR_GREEN, COLOR_ORANGE, COLOR_RED } from '@/constants';
import { DropdownCaret } from '@/views/common/DropMenu';
import { MenuBackground } from '@/views/common/DropMenu/StyledMenuContainer';
export var useMuiTheme = function useMuiTheme() {
  return createTheme({
    palette: {
      primary: {
        main: COLOR_BOLD_BLUE
      },
      error: {
        main: COLOR_RED
      },
      warning: {
        main: COLOR_ORANGE
      },
      success: {
        main: COLOR_GREEN
      }
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&': "".concat({
              "backgroundColor": "rgba(var(--contrast-color), 0.9)",
              "--tw-backdrop-blur": "blur(20px)",
              "backdropFilter": "var(--tw-backdrop-filter)",
              "borderWidth": "1px",
              "borderStyle": "solid",
              "borderColor": "rgba(var(--primary-color), 0.1)",
              "boxSizing": "border-box",
              "overflow": "auto"
            }, " ").concat(MenuBackground)
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            "color": "rgb(var(--primary-color))",
            "fontWeight": "300",
            "fontSize": "0.875rem",
            "paddingTop": "0",
            "paddingBottom": "0"
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            "color": "rgb(var(--primary-color))",
            "fontWeight": "300",
            "fontSize": "0.875rem"
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: _.extend({
            ":hover": {
              "backgroundColor": "rgba(var(--primary-color), 0.05) !important",
              "whiteSpace": "normal !important"
            }
          }, {
            '&.Mui-selected': {
              backgroundColor: 'inherit'
            }
          })
        }
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            '&': {
              "padding": "0 !important",
              "paddingRight": "9px !important"
            },
            '& .MuiSvgIcon-root': {
              fontSize: 20,
              color: 'currentColor'
            }
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            font: 'inherit'
          }
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: 'outlined',
          IconComponent: DropdownCaret
        },
        styleOverrides: {
          select: {
            '&': {
              "transitionProperty": "background-color, border-color, color, fill, stroke",
              "transitionDuration": "200ms",
              "paddingTop": "0.375rem !important",
              "paddingBottom": "0.375rem !important",
              "paddingLeft": "0.875rem !important",
              "paddingRight": "2rem !important",
              "borderRadius": "0.125rem",
              "fontWeight": "600",
              "color": "rgba(var(--primary-color), 0.9)",
              "fontSize": "14px",
              "borderWidth": "1px",
              "borderStyle": "solid",
              "borderColor": "rgba(var(--primary-color), 0.15)",
              "backgroundColor": "rgba(var(--primary-color), 0.02)"
            },
            '&:hover': {
              "backgroundColor": "rgba(var(--primary-color), 0.05)",
              "borderColor": "rgba(var(--primary-color), 0.3)",
              "color": "rgba(var(--primary-color), 1)"
            },
            '&:active': {
              "backgroundColor": "rgba(var(--primary-color), 0.1)",
              "borderColor": "rgba(var(--primary-color), 1)",
              "color": "rgba(var(--primary-color), 1)"
            },
            '&:focus': {
              "outline": "2px solid transparent",
              "outlineOffset": "2px"
            }
          },
          icon: {
            right: '1rem !important',
            top: 'calc(50% - 7px)'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderWidth: 0
          },
          root: {
            '&': {
              "fontFamily": "\"Manrope\", system-ui, -apple-system, Arial"
            },
            '&.Mui-focused': {
              '.MuiOutlinedInput-notchedOutline': {
                borderWidth: 0
              }
            }
          }
        }
      }
    }
  });
};