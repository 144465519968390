var _$first;
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { BUILD_CONFIG } from '@/buildConfig';
import { NetworkStatus, SettingKey } from './types';
import { countryCodes, emptySettings, envKeys, moduleKeys } from './utils';
var initialState = {
  saveStatus: NetworkStatus.NO_STATUS,
  country: (_$first = _.first(BUILD_CONFIG.moduleSettings.countries)) !== null && _$first !== void 0 ? _$first : '',
  env: 'PROD',
  allSettings: countryCodes(envKeys(moduleKeys(emptySettings()))),
  currentSettings: moduleKeys(emptySettings())
};
var moduleSettingsSlice = createSlice({
  name: 'moduleSettings',
  initialState: initialState,
  reducers: {
    setCountry: function setCountry(state, action) {
      var _state$allSettings, _state$allSettings$st;
      state.country = action.payload;
      state.currentSettings = (_state$allSettings = state.allSettings) === null || _state$allSettings === void 0 ? void 0 : (_state$allSettings$st = _state$allSettings[state.country]) === null || _state$allSettings$st === void 0 ? void 0 : _state$allSettings$st[state.env];
    },
    setEnv: function setEnv(state, action) {
      state.env = action.payload;
      state.currentSettings = state.allSettings[state.country][state.env];
    },
    setSkuLookup: function setSkuLookup(state, action) {
      state.skuLookup = action.payload;
    },
    setHistory: function setHistory(state, action) {
      state.history = action.payload;
    },
    setSetting: function setSetting(state, _ref) {
      var _state$allSettings2, _state$allSettings2$s;
      var _ref$payload = _ref.payload,
        module = _ref$payload.module,
        country = _ref$payload.country,
        setting = _ref$payload.setting,
        env = _ref$payload.env,
        value = _ref$payload.value;
      if (!Object.keys(state.allSettings).includes(country)) {
        throw new Error("Invalid country ".concat(country, ", valid options: ").concat(Object.keys(state.allSettings)));
      }
      if (!Object.keys(state.allSettings[country]).includes(env)) throw new Error("Invalid env ".concat(env));
      if (!Object.keys(state.allSettings[country][env]).includes(module)) throw new Error("Invalid module ".concat(module));
      if ((setting === SettingKey.strategicSkus || setting === SettingKey.defaultRecs) && !_.isArray(value)) throw new Error("Setting ".concat(env, ".").concat(country, ".").concat(module, ".").concat(setting, " should be array but is not"));
      state.allSettings[country][env][module][setting] = value;
      state.currentSettings = (_state$allSettings2 = state.allSettings) === null || _state$allSettings2 === void 0 ? void 0 : (_state$allSettings2$s = _state$allSettings2[state.country]) === null || _state$allSettings2$s === void 0 ? void 0 : _state$allSettings2$s[state.env];
    },
    resetSettings: function resetSettings(state) {
      var _state$allSettings3, _state$allSettings3$s;
      state.allSettings = initialState.allSettings;
      state.currentSettings = (_state$allSettings3 = state.allSettings) === null || _state$allSettings3 === void 0 ? void 0 : (_state$allSettings3$s = _state$allSettings3[state.country]) === null || _state$allSettings3$s === void 0 ? void 0 : _state$allSettings3$s[state.env];
    },
    setLoadingFailed: function setLoadingFailed(state) {
      state.saveStatus = NetworkStatus.LOADING_FAILED;
    },
    setLoadingSucceeded: function setLoadingSucceeded(state) {
      state.saveStatus = NetworkStatus.NO_STATUS;
    },
    setSaveStatusNull: function setSaveStatusNull(state) {
      state.saveStatus = NetworkStatus.NO_STATUS;
    },
    setSaveStatusNotSaved: function setSaveStatusNotSaved(state) {
      state.saveStatus = NetworkStatus.CHANGES_NOT_SAVED;
    },
    setSaveStatusIsSaving: function setSaveStatusIsSaving(state) {
      state.saveStatus = NetworkStatus.SAVING_CHANGES;
    },
    setSaveStatusSaved: function setSaveStatusSaved(state) {
      state.saveStatus = NetworkStatus.CHANGES_ARE_SAVED;
    },
    setStatusLoading: function setStatusLoading(state) {
      state.saveStatus = NetworkStatus.LOADING;
    }
  }
});
var _moduleSettingsSlice$ = moduleSettingsSlice.actions,
  setCountry = _moduleSettingsSlice$.setCountry,
  setEnv = _moduleSettingsSlice$.setEnv,
  setSkuLookup = _moduleSettingsSlice$.setSkuLookup,
  setHistory = _moduleSettingsSlice$.setHistory,
  resetSettings = _moduleSettingsSlice$.resetSettings,
  setSetting = _moduleSettingsSlice$.setSetting,
  setLoadingFailed = _moduleSettingsSlice$.setLoadingFailed,
  setLoadingSucceeded = _moduleSettingsSlice$.setLoadingSucceeded,
  setSaveStatusNull = _moduleSettingsSlice$.setSaveStatusNull,
  setSaveStatusIsSaving = _moduleSettingsSlice$.setSaveStatusIsSaving,
  setSaveStatusNotSaved = _moduleSettingsSlice$.setSaveStatusNotSaved,
  setSaveStatusSaved = _moduleSettingsSlice$.setSaveStatusSaved,
  setStatusLoading = _moduleSettingsSlice$.setStatusLoading;
export { setCountry, setEnv, setSkuLookup, setHistory, resetSettings, setSetting, setLoadingFailed, setLoadingSucceeded, setSaveStatusNull, setSaveStatusIsSaving, setSaveStatusNotSaved, setSaveStatusSaved, setStatusLoading };
export var moduleSettingsReducer = moduleSettingsSlice.reducer;