export var COUNTRY_CODE_TO_VIEWPORT = {
  AR: [-34.6, -58.4, 5],
  HN: [-34.6, -58.4, 5],
  BR: [-16.06, -48.0, 4],
  CO: [4.655, -74.062, 6],
  CA: [51.0, -114.0, 6],
  DO: [19.45, -70.71, 7],
  MX: [19.43, -99.135, 5],
  PA: [8.97, -79.533, 7],
  PE: [-12.04, -77.03, 5],
  ZA: [-26.2, 28.04, 5],
  EC: [-0.22, -78.51, 6],
  PY: [-25.26, -57.66, 6],
  SV: [13.698, -89.191, 8],
  UY: [-34.53, -56.1, 6],
  US: [39.5, -98.35, 4],
  SG: [1.3, 103.8, 7],
  GB: [55.37, -3.43, 7],
  AU: [-24.27, 132.7, 6],
  DE: [51.5, 10.5, 6],
  IE: [53.4, -8.25, 7],
  IT: [41.9, 12.5, 6],
  DK: [56.26, 9.5, 6],
  FR: [46.2, 2.2, 6],
  JP: [36.2, 138.25, 6],
  TR: [39.9, 32.8, 6],
  HU: [47.5, 19.05, 6],
  ES: [40.4, -3.68, 6],
  UG: [1.32, 32.29, 6],
  TZ: [-6.8, 35.75, 6],
  WORLD: [39.5, -98.35, 9]
};