import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useQuery } from './useQuery';
export var useModelIDsContextValue = function useModelIDsContextValue() {
  var _useQuery = useQuery('/exports/api/imports'),
    _useQuery2 = _slicedToArray(_useQuery, 3),
    modelIDs = _useQuery2[0],
    modelIDsLoading = _useQuery2[1],
    modelIDsError = _useQuery2[2];
  return {
    modelIDs: modelIDs,
    modelIDsLoading: modelIDsLoading,
    modelIDsError: modelIDsError
  };
};