import { BUILD_CONFIG } from '@/buildConfig';
function retrieveBaseUrl(localPort, suffix) {
  var _BUILD_CONFIG$arenaGe;
  if ((_BUILD_CONFIG$arenaGe = BUILD_CONFIG.arenaGeneral.apiUrl) !== null && _BUILD_CONFIG$arenaGe !== void 0 && _BUILD_CONFIG$arenaGe.endsWith('localhost')) return "".concat(BUILD_CONFIG.arenaGeneral.apiUrl, ":").concat(localPort, "/").concat(suffix);
  return "".concat(BUILD_CONFIG.arenaGeneral.apiUrl, "/").concat(suffix);
}
var constructSsoLoginUrl = function constructSsoLoginUrl(devPath, prodPath) {
  if (BUILD_CONFIG.isProduction) {
    return "".concat(BUILD_CONFIG.arenaGeneral.apiUrl, "/").concat(prodPath, "?next=").concat(encodeURIComponent(window.location.href));
  }
  return "".concat(BUILD_CONFIG.arenaGeneral.apiUrl, "/").concat(devPath, "?next=").concat(encodeURIComponent(window.location.href));
};
export var ssoLoginEndpoint = function ssoLoginEndpoint() {
  return constructSsoLoginUrl('auth-service/sso/saml/azure-sso-dev/login/', 'auth-service/sso/saml/azure-sso/login/');
};
export var googleLoginEndpoint = function googleLoginEndpoint() {
  var path = 'auth-service/sso-v2/login/google-oauth2/';
  return constructSsoLoginUrl(path, path);
};
export var microsoftLoginEndpoint = function microsoftLoginEndpoint() {
  var path = 'auth-service/sso-v2/login/azuread-oauth2/';
  return constructSsoLoginUrl(path, path);
};
export var GENERAL_BASE_URL = retrieveBaseUrl(8000, '');
export var ABACUS_BASE_URL = retrieveBaseUrl(8000, 'abacus/');
export var AUTH_BASE_URL = retrieveBaseUrl(8081, 'auth-service/api/auth/');
export var PROMOS_BASE_URL = retrieveBaseUrl(8080, 'promos/graphql/');
export var EXPERIMENTS_BASE_URL = retrieveBaseUrl(8080, 'experiments/api/v1/');
export var TABLE_BASE_URL = retrieveBaseUrl(8080, 'table-service/api/');
export var SENTRY_BASE_URL = 'https://591bbb377eac4c38be857d8cb5c0bd0e@o824538.ingest.sentry.io/5810864';
export var EXTERNAL_ABACUS_BASE_URL = "".concat(BUILD_CONFIG.dashboard.beesApiUrl, "/api/algo-metrics-service/v0/");
export var EXTERNAL_ABACUS_URLS = ['modules/QUICK_ORDER/metrics/incrementality_adjusted', 'modules/QUICK_ORDER/metrics/performance_month', 'modules/QUICK_ORDER/metrics/model_health', 'modules/UPSELL_FORGOTTEN_ITEMS/metrics/incrementality_month', 'modules/UPSELL_FORGOTTEN_ITEMS/metrics/performance_month', 'modules/UPSELL/metrics/model_health', 'modules/OVERALL/metrics/performance_month', 'modules/OVERALL/metrics/performance_algo_month'];
export var HELP_US_BASE_URL = 'https://api.arena-ai.com/';
export var TABLES_LIST = "".concat(BUILD_CONFIG.arenaGeneral.apiUrl, "/table-service/api/tables/v2/registration/list?limit=9999999999999");
export var TABLES_UPSERT = "".concat(BUILD_CONFIG.arenaGeneral.apiUrl, "/table-service/api/tables/registration/upsert");
export var TABLE_DESCRIBE = function TABLE_DESCRIBE(alias, partition) {
  return "".concat(BUILD_CONFIG.arenaGeneral.apiUrl, "/table-service/api/tables/registration/describe/").concat(partition, "/").concat(alias);
};