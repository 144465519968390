import { DEPLOYMENT, DeploymentKey, NavBarItemKey } from '@/buildConfig';
import { ColombiaRegion } from '@/views/containers/SkuLimits/constants/fromClient';
import { useHasAllAccess } from '@/views/containers/SkuLimits/hooks/useHasAllAccess';
import { useHasAccess } from './useHasAccess';
export var useNavItemFilter = function useNavItemFilter() {
  var _useHasAllAccess = useHasAllAccess(),
    accessTable = _useHasAllAccess.accessTable;
  var isArena = useHasAccess(['arena-staff']);
  function filterForSKULimits(navKey) {
    if (DEPLOYMENT === DeploymentKey.antarctica) {
      if (isArena) return true;
      if (accessTable === undefined) return true;
      if (accessTable === ColombiaRegion.all) return true;
      return navKey === NavBarItemKey.SmartQuotas;
    }
    return true;
  }
  return {
    filter: filterForSKULimits
  };
};