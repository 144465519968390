import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createSlice } from '@reduxjs/toolkit';
import equal from 'fast-deep-equal';
import moment from 'moment';
import { notEmpty } from '@/utils';
import { POC_RETAIN_OPTION, SKU_RETAIN_OPTION } from './constants';
import { skuToConfiguredSku } from './utils';
var emptyPOCUniverseFilterConfig = {
  geographyFilters: [],
  geography2Filters: [],
  geography3Filters: [],
  businessTypeFilters: [],
  businessType2Filters: [],
  businessType3Filters: [],
  pocsToInclude: [],
  pocsToExclude: []
};
var emptyClearStateOptions = [{
  name: SKU_RETAIN_OPTION,
  label: 'Retain Product Selection',
  checked: false
}, {
  name: POC_RETAIN_OPTION,
  label: 'Retain POC Filter Selection',
  checked: false
}];
var initialState = {
  selectedCountry: 'DO',
  selectedStrategy: undefined,
  selectedSubstrategy: undefined,
  selectedStartDate: moment(),
  selectedEndDate: null,
  budget: undefined,
  budgetLoading: false,
  availableSKUs: [],
  selectedSKUs: [],
  skuDiscountGuidanceSpecs: [],
  pocUniverseOptions: undefined,
  pocUniverseFilterConfig: emptyPOCUniverseFilterConfig,
  pocUniverseConstraints: [],
  pocUniverseSpec: undefined,
  clearStateOptions: emptyClearStateOptions
};
var createPromosSlice = createSlice({
  name: 'createPromos',
  initialState: initialState,
  reducers: {
    selectStrategy: function selectStrategy(state, action) {
      if (state.selectedStrategy !== action.payload) {
        state.selectedSubstrategy = undefined;
      }
      state.selectedStrategy = action.payload;
    },
    selectSubstrategy: function selectSubstrategy(state, action) {
      state.selectedSubstrategy = action.payload;
    },
    selectCountryCode: function selectCountryCode(state, action) {
      return _objectSpread(_objectSpread({}, initialState), {}, {
        selectedStrategy: state.selectedStrategy,
        selectedSubstrategy: state.selectedSubstrategy,
        selectedCountry: action.payload,
        selectedStartDate: state.selectedStartDate,
        selectedEndDate: state.selectedEndDate
      });
    },
    selectDates: function selectDates(state, action) {
      var _action$payload = action.payload,
        startDate = _action$payload.startDate,
        endDate = _action$payload.endDate;
      state.selectedStartDate = startDate;
      state.selectedEndDate = endDate;
    },
    setBudget: function setBudget(state, action) {
      var budget = action.payload;
      state.budget = budget;
    },
    setBudgetLoading: function setBudgetLoading(state, action) {
      state.budgetLoading = action.payload;
    },
    loadSkus: function loadSkus(state, action) {
      var skus = action.payload;
      state.availableSKUs = skus;
    },
    setSelectedSKUs: function setSelectedSKUs(state, action) {
      var skus = action.payload;
      state.selectedSKUs = skus;
    },
    updateSelectedSKU: function updateSelectedSKU(state, action) {
      var sku = action.payload;
      state.selectedSKUs[state.selectedSKUs.findIndex(function (s) {
        return s.sku === sku.sku;
      })] = sku;
    },
    setSKUDiscountGuidanceSpecs: function setSKUDiscountGuidanceSpecs(state, action) {
      state.skuDiscountGuidanceSpecs = action.payload;
    },
    setPOCUniverseOptions: function setPOCUniverseOptions(state, action) {
      var options = action.payload;
      if (!equal(state.pocUniverseOptions, options)) {
        state.pocUniverseFilterConfig = emptyPOCUniverseFilterConfig;
        state.pocUniverseOptions = options;
      }
    },
    setPOCUniverseFilterConfig: function setPOCUniverseFilterConfig(state, action) {
      var config = action.payload;
      state.pocUniverseFilterConfig = config;
    },
    setPOCUniverseConstraints: function setPOCUniverseConstraints(state, action) {
      var constraints = action.payload;
      state.pocUniverseConstraints = constraints;
    },
    setPOCUniverseSpec: function setPOCUniverseSpec(state, action) {
      var spec = action.payload;
      state.pocUniverseSpec = spec;
    },
    setRetainPOCOptions: function setRetainPOCOptions(state) {
      var option = state.clearStateOptions.find(function (el) {
        return el.name === POC_RETAIN_OPTION;
      });
      if (notEmpty(option)) {
        option.checked = !option.checked;
      }
    },
    setRetainSKUOptions: function setRetainSKUOptions(state) {
      var option = state.clearStateOptions.find(function (el) {
        return el.name === SKU_RETAIN_OPTION;
      });
      if (notEmpty(option)) {
        option.checked = !option.checked;
      }
    },
    clearStateAfterSubmission: function clearStateAfterSubmission(state) {
      var clearState = initialState;
      var poc = state.clearStateOptions.find(function (el) {
        return el.name === POC_RETAIN_OPTION;
      });
      var sku = state.clearStateOptions.find(function (el) {
        return el.name === SKU_RETAIN_OPTION;
      });
      if (notEmpty(poc) && poc.checked) {
        clearState = _objectSpread(_objectSpread({}, clearState), {}, {
          pocUniverseFilterConfig: state.pocUniverseFilterConfig,
          pocUniverseSpec: state.pocUniverseSpec
        });
      }
      if (notEmpty(sku) && sku.checked) {
        var configuredSKUs = state.selectedSKUs.map(function (el) {
          return skuToConfiguredSku(el, state.selectedCountry);
        });
        clearState = _objectSpread(_objectSpread({}, clearState), {}, {
          availableSKUs: state.availableSKUs,
          selectedSKUs: configuredSKUs
        });
      }
      clearState = _objectSpread(_objectSpread({}, clearState), {}, {
        pocUniverseOptions: state.pocUniverseOptions
      });
      return clearState;
    },
    purgeState: function purgeState(state) {
      return _objectSpread(_objectSpread({}, state), initialState);
    }
  }
});
var _createPromosSlice$ac = createPromosSlice.actions,
  selectStrategy = _createPromosSlice$ac.selectStrategy,
  selectSubstrategy = _createPromosSlice$ac.selectSubstrategy,
  selectCountryCode = _createPromosSlice$ac.selectCountryCode,
  selectDates = _createPromosSlice$ac.selectDates,
  setBudget = _createPromosSlice$ac.setBudget,
  setBudgetLoading = _createPromosSlice$ac.setBudgetLoading,
  loadSkus = _createPromosSlice$ac.loadSkus,
  setSelectedSKUs = _createPromosSlice$ac.setSelectedSKUs,
  updateSelectedSKU = _createPromosSlice$ac.updateSelectedSKU,
  setSKUDiscountGuidanceSpecs = _createPromosSlice$ac.setSKUDiscountGuidanceSpecs,
  setPOCUniverseOptions = _createPromosSlice$ac.setPOCUniverseOptions,
  setPOCUniverseFilterConfig = _createPromosSlice$ac.setPOCUniverseFilterConfig,
  setPOCUniverseConstraints = _createPromosSlice$ac.setPOCUniverseConstraints,
  setPOCUniverseSpec = _createPromosSlice$ac.setPOCUniverseSpec,
  clearStateAfterSubmission = _createPromosSlice$ac.clearStateAfterSubmission,
  setRetainPOCOptions = _createPromosSlice$ac.setRetainPOCOptions,
  setRetainSKUOptions = _createPromosSlice$ac.setRetainSKUOptions,
  purgeState = _createPromosSlice$ac.purgeState;
export { selectStrategy, selectSubstrategy, selectCountryCode, selectDates, setBudget, setBudgetLoading, loadSkus, setSelectedSKUs, updateSelectedSKU, setSKUDiscountGuidanceSpecs, setPOCUniverseOptions, setPOCUniverseFilterConfig, setPOCUniverseConstraints, setPOCUniverseSpec, clearStateAfterSubmission, setRetainPOCOptions, setRetainSKUOptions, purgeState };
export function isConfiguredSKUValid(sku) {
  return sku.minQuantity !== undefined && sku.maxQuantity !== undefined && sku.minQuantity !== undefined && sku.minDiscountPct !== undefined && sku.maxDiscountPct !== undefined && sku.allocationPct !== undefined;
}
export var createPromosReducer = createPromosSlice.reducer;