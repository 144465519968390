export var UPSELL_BUSINESS_RULES_DOC = {
  label: 'Business rules',
  fileID: 'docs/upsell_business_rules.pdf'
};
export var SUGGESTED_ORDER_DOCS = [{
  label: 'Overview',
  fileID: 'docs/feb_2021_suggested_order_overview.pdf'
}, {
  label: 'Metrics',
  fileID: 'docs/mar_2022_suggested_order_metrics.pdf'
}, {
  label: 'Business rules',
  fileID: 'docs/suggested_order_business_rules.pdf'
}, {
  label: 'Neural Cart Ranker for Suggested Order',
  fileID: 'docs/july_2022 NCR for QO Overview.pdf'
}];
export var UPSELL_DOCS = [{
  label: 'Overview',
  fileID: 'docs/mar_2021_upsell_overview.pdf'
}, UPSELL_BUSINESS_RULES_DOC];
export var PROMOTION_DOCS = [{
  label: 'Overview',
  fileID: 'docs/mar_2021_promo_session.pdf'
}];
export var EXPERIMENT_DOCS = [{
  label: 'Metrics',
  fileID: 'docs/dexter_KPI.pdf'
}, {
  label: 'Reranker Experiment Learnings - DO - 02/03/2021 to 03/21/2021',
  fileID: 'docs/DO-Reranker-Experiment-2021-02-03.pdf'
}];
export var BUSINESS_DOCS = [{
  label: 'AB San Diego Market Visit Report',
  fileID: 'docs/SD_Market_Visit_3_2022.pdf'
}];
export var TECHNICAL_DOCS = [{
  label: 'Promotions model - March 2021 - Dominican Republic',
  fileID: 'docs/mar_2021_promo_model.docx'
}, {
  label: 'Non-negative matrix factorization recommender',
  fileID: 'docs/nmf_model_template.docx'
}, {
  label: 'Wide and deep model',
  fileID: 'docs/wide_deep_template.docx'
}, {
  label: 'Licenses of software dependencies',
  fileID: 'docs/nov_2021_node_licenses.csv'
}, {
  label: 'ABI Global Algorithmic Selling Recommendations Technical Spec',
  fileID: 'docs/ABI Global Algorithmic Selling Recommendations Technical Spec.pdf'
}, {
  label: 'Data Pipelines',
  fileID: 'docs/feb_2021_data_pipelines.pdf'
}];
export var RETOOLS_ARENA = [{
  label: 'Monitor data exports',
  url: 'https://frozenarc.tryretool.com/apps/Exports'
}, {
  label: 'Map an old SKU to a new SKU to assist recommendations',
  url: 'https://frozenarc.tryretool.com/apps/Product%20Map%20Corrections'
}];
export var RETOOLS_BEES = [{
  label: 'See upsell recommendations and similar products per account',
  url: 'https://retool.beesdata.science/apps/Engin/upsell%20recommendations'
}];